.main{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  min-width: 1000px;
  flex-direction: row;
}



.btnPDF:hover{
  cursor: pointer;
}

.btnContainer{
  margin-top: 2rem;
  width: 80%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.btnContainer button{
  border: none;
  margin: 0.5rem;
}

.btnContainer button{
  cursor: pointer;
}

.full{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.totem{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.disable{
  filter: opacity(0.3);
}

.disable:hover{
  cursor: not-allowed;
}