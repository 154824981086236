.loginMain{
  background-color: '#000';
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logBox{
  height: 600px;
  width: 400px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.2), 0 2px 30px 0 rgba(0, 0, 0, 0.35);
  border-radius: 0.5rem;
}

.logBox img{
  width: 160px;
  height: 160px;
  margin-bottom: 1rem;
}

.logBox span{
  font-size: 14px;
  text-align: center;
  width: 80%;
}

.logBox h1{
  font-size: 28px;
  color:#000080;
  margin: 0.5rem;
}

.logBox input{
  margin: 0.5rem 0;
  width: 350px;
  height: 50px;
  background-color:#c4c4c4;
  border: none;
  border-radius: 5px;
  padding-left: 0.4rem;
}

.logBox input::placeholder{
  color:#000;
}

.check{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.check input{
  margin-right: 0.2rem;
  margin-bottom: 0.5rem;
  width: 20px;
  height: 20px;
}

.logBox button{
  width: 360px;
  height: 50px;
  background-color:#fff;
  border: 3px solid #000080;
  margin: 0.5rem 0;
  color:#000080;
  border-radius: 5px;
  font-weight: bold;
}

.logBox button:hover{
  cursor: pointer;
  background-color:#000080;
  color:#fff;
  font-weight: bold;
}

.logBox form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logBox a{
  text-decoration: none;
  color: #000;
  font-weight: bold;
}