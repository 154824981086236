.boxInfo{
  border: 1px solid #000;
  width: 250px;
  height: 180px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 2rem;
}

.boxInfo:hover{
  box-shadow: 1px 8px 20px grey;
  -webkit-transition:  box-shadow .6s ease-in;
}

.boxInfo h1{
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  align-self: flex-start;
  width: 100%;
  border-bottom: 1px solid #000;
}

.boxInfo span{
  font-size: 96px;
  margin-bottom: 1.5rem;
}