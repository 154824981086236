.tableContainer{
  width: 90%;
  margin: 2rem 0;
}

.btn{
  width: 160px;
  border: 1px solid #2C4CBD;
  background-color: #fff;
  color: #2C4CBD;
  height: 30px;
}

.btn:hover{
  background-color: #2C4CBD;
  color: #fff;
  cursor: pointer;
}

.imgBTN{
  border: none;
}

.imgBTN:hover{
  cursor: pointer;
}
