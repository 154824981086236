.header{
  width: 100vw;
  background-color: #000;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 1000px;
}

.logout{
  margin-left: 2rem;
  background-color: #fff; 
  border-radius: 5px; 
  height: 30px;
  width: 80px;
}

.logout:hover{
  cursor: pointer;
}

.leftSide{
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightSide{
  margin-right: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.rightSide p{
  color: #fff;
  margin: 0 2rem;
}