.card{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 300px;
  height: 450px;
  border-radius: 4px;
  border: 1px solid #000;
  margin: 1rem;
}

.cardImg{
  height: 30%;
  width: 100%;
}

.cardImg img{
  height: 100%;
  width: 100%;
}

.cardInfos{
  height: 10%;
  display: flex;
  width: 95%;
  justify-content: space-between;
  align-items: center;
}

.cardBtn{
  height: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 80%;
}

.cardBtn button{
  color: #3250BD;
  font-size: 20px;
  background-color: #fff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardBtn button:hover{
  cursor: pointer;
}

.divisor{
  background-color: rgba(126, 121, 121, 0.21);
  width: 100%;
  height: 1px;
}